import "./App.css";
import React, { useEffect, useState } from "react";

import { FileUploader } from "./FileUploader";
import { GenerateButton } from "./GenerateButton";
import { NavBar } from "./NavBar";

function App() {
  const [fileName, setFileName] = useState("");
  const [image_src, setImage_Src] = useState("placeholder.svg");
  const [file, setFile] = useState("placeholder.svg");
  const handleFile = (file) => {
    setFile(URL.createObjectURL(file));
    setFileName(file.name);
  };

  const handleImageSrc = (imagesrc) => {
    setImage_Src(imagesrc);
  };

  return (
    <div className="App">
      <NavBar />
      <header className="App-header">
        <FileUploader handleFile={handleFile} />
        {fileName ? <GenerateButton setImage={handleImageSrc} /> : null}
      </header>
      <div id="twoimages">
        <div class="gendiv">
          <img class="imagespacer" id="prev" src={file} />
        </div>
        <div class="gendiv">
          <img class="centercrop imagespacer" src={image_src} />
        </div>
      </div>

      <a
        href="https://www.cutercounter.com/"
        target="_blank"
        className="counter"
      >
        <img
          src="https://www.cutercounter.com/hits.php?id=hxnckdd&nd=6&style=1"
          border="0"
          alt="hit counter"
        />
      </a>
    </div>
  );
}

export default App;
