import { useState } from "react";
import "./styles.css";
import "./button.css";
export const GenerateButton = ({ setImage }) => {
  var images = [
    "professional1.jpg",
    "professional2.jpg",
    "professional3.jpg",
    "professional4.jpg",
    "professional5.jpg",
    "professional6.jpg",
    "professional7.jpg",
    "professional8.jpg",
    "professional9.jpg",
    "professional10.jpg",
    "professional11.jpg",
    "professional12.jpg",
    "professional13.jpg",
    "professional14.jpg",
    "professional15.jpg",
    "professional16.jpg",
  ];

  var random = Math.floor(Math.random() * images.length);

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  // Randomly upload a photo of a white dude
  // when the Button component is clicked
  const handleClick = (event) => {
    setTimeout(function () {
      setShow(true);
      const idx = Math.floor(Math.random() * images.length);
      setIndex(idx);
      setImage(images[idx]);
    }, 1000);
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file

  return (
    <>
      <button
        className="button"
        onClick={handleClick}
        style={{ fontFamily: "Quicksand" }}
      >
        Get professional headshot
      </button>
    </>
  );
};

//       {show && <img src={images[index]} width={"600em"} />}
